import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import { LinkBox } from '@components/core'

// boxShadow: 'rgba(0, 0, 0, 0.22) 0px 19px 43px',
// transform: 'translate3d(0px, -1px, 0px)',

//  cursor-pointer

const QuickstartCard = React.memo(({ name, img, href, displayMode, alt, ...rest }) => {
  return (
    <LinkBox
      className={classNames(
        'font-medium text-gray-700',
        'flex flex-col items-center justify-center ',
        {'w-32 h-32': displayMode !== "sm"},
        {'w-[7rem] h-[7rem]': displayMode === "sm"}
      )}
      href={href}
      {...rest}
    >
      <GatsbyImage
        imgStyle={{ padding: 0, margin: 0 }}
        image={getImage(img)}
        alt={alt}
        loading='lazy'
      />
      <p className={classNames("m-0 mt-2 leading-tight text-center", {"text-[0.75rem]" : displayMode === "sm"})}>{name}</p>
    </LinkBox>
  )
})

export default QuickstartCard
