import React from 'react'
import { navigate } from "gatsby";
import {
  Seo,
  Zoom,
} from '@components'
import { PageLayout } from '../../../components/site/layout/page-layout';
import { 
  RightSidebar, 
  ArchitectureImage, 
  ArchitectureImage2, 
  AppDeviceAttestationBanner 
} from '../../../components/site/product';

import { BenefitCard } from "../../../components/core/benefit-card";
import { CloudIcon } from '../../../components/core/icons/cloud'
import { CryptoSecurityIcon } from '../../../components/core/icons/crypto_security'
import { PlagformAttestationIcon } from '../../../components/core/icons/platform_attestation'

const AppAttestationPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      <AppDeviceAttestationBanner title="Approov Mobile App Attestation"/>
      <div className="container pt-[6rem] pb-[70px] text-[#262727]">
        <div className="w-full flex flex-col lg:flex-row px-[32px] gap-[38px]">
          {/* Left Side */}
          <div className="w-full">
              <div className="flex flex-col px-[20px] md:px-[28px] py-[30px] md:py-[40px] items-start gap-[15px] rounded-[10px] bg-[#F2F5F9]">
                  <h2 className="text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">
                    App Attestation
                  </h2>
                  <div className="w-full xs:w-[230px] h-[4px] bg-[#B21C7A]"></div>
                  <p className="text-[17px] leading-[29px] m-0">
                    App Attestation is a runtime technique used to provide proof that an app is truly authentic and running in a trusted device. It uses a combination of measurements made on the client but checked by a remote attestation server.
                  </p>
              </div>

              <h2 className="mt-[50px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Benefits</h2>
              <BenefitCard
                className="mt-[20px]"
                Icon={
                  <CloudIcon className="w-[55px] h-[55px] flex-shrink-0" />
                }
                title="Cloud-Based Security"
                text="Migrate security decisions into the cloud for greater security and flexibility"
              />
              <div className="mt-[25px] w-full flex flex-col md:flex-row gap-[18px]">
                <BenefitCard
                  className="md:w-1/2"
                  Icon={
                    <CryptoSecurityIcon className="w-[55px] h-[55px] flex-shrink-0" />
                  }
                  title="Сryptographic Security"
                  text="Token based cryptographic security with no secrets in the app"
                />
                <BenefitCard
                  className="md:w-1/2"
                  Icon={
                    <PlagformAttestationIcon className="w-[55px] h-[55px] flex-shrink-0" />
                  }
                  title="Platform Attestation"
                  text="Optional integration of platform attestation for additional security"
                />
              </div>
              <div className="w-full flex justify-center mt-[40px]">
                <button className="text-[16px] text-[#fff] font-medium leading-[28px] bg-[#78A65A] px-[38px] py-[16px] rounded-[7px]"
                  onClick={() => {
                    navigate('https://approov.io/info/schedule-a-demo')
                  }}
                >
                  Request a Demo
                </button>
              </div>

              <h2 className="mt-[80px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Definitive Attestation</h2>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                Traditional app security approaches integrate all of the threat detection logic into the app itself, which is running in an attacker controlled environment. Even if the detection code is well defended, it relies on the app making a local decision about it’s own integrity, which presents an obvious weak point that is frequently exploited by attackers.
              </p>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                Approov is different. It uses a remote attestation approach, where the running app must prove itself to be genuine through a sequence of integrity measurements. These results are then sent to the Approov cloud service using a patented challenge-response protocol, immune from replay attacks. The Approov cloud makes the decisions.
              </p>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                If integrity is verified then the running app is issued with a short lived cryptographic token that it can use to prove its authenticity to the backend API services it uses. The app cannot make its own decisions about integrity and cannot sign its own tokens. Defense is moved out of the attacker’s reach and into the Approov cloud.
              </p>
              <Zoom>{ArchitectureImage}</Zoom>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Google Play Integrity and iOS App Attest Integrated</h2>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                Approov provides more granular control, wider device support, cross-platform consistency and various other advantages over the basic platform capabilities. However, Approov does optionally integrate with iOS App Attest / DeviceCheck and Google Play Integrity to provide the most comprehensive attestation and powerful threat management framework.
              </p>
              <Zoom>{ArchitectureImage2}</Zoom>

              {/* <div className="mt-[70px] flex justify-center px-[90px] py-[40px] items-start gap-[15px] rounded-[10px] bg-[#F2F5F9]">
                  <h2 className="text-center text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">
                    Recommended Resources About Approov Mobile RASP Security
                  </h2>
              </div> */}
          </div>
          {/* Right Side */}
          <RightSidebar location={location.pathname} />
        </div>
      </div>
    </PageLayout>
  )
}

export default React.memo(AppAttestationPage)
